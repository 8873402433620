import { Injectable, NgZone } from '@angular/core';
import { DevicesService } from './devices.service';

var something;
declare var mqtt;
@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  uuid:any = "";
  user:any = "";

  mqttClient:any;
  
  waitDevice:any = "";
  waitCommand:any = "";
  addModal:any;

  askingDevice:any = "";
  askingModal:any;

  connectingDevice:any = "";
  connectingModal:any;
  connectingWifi:any = "";

  scanningIRDevice:any = "";
  scanningIRModal:any;

  updatePhoneInterval:any

  additonalUsers:any = [];

  private TOPIC: string[] = [];

  constructor(public deviceServ: DevicesService, private zone:NgZone) {
    something = this;
  }

  connectUs(user) { 
    this.mqttClient = mqtt.connect('wss://wifi-ac-eu1.icontrol.bg:9002', { username:'tempex', password:'OTW6bGGMNa', clean: true, connectTimeout: 4000 });

    this.mqttClient.on('connect', function () {
      something.mqttClient.subscribe('user/' + something.user, function (err) {
        if (!err) { }
      });

      for(let i = 0; i < something.additonalUsers.length; i++){
        something.mqttClient.subscribe('user/' + something.additonalUsers[i], function (err) {
          if (!err) { }
        });
      }

      something.mqttClient.subscribe('devices', function (err) {
        if (!err) { }
      });


      something.mqttClient.subscribe('phone-user/' + something.user, function (err) {
        if (!err) { }
      });

      something.mqttClient.subscribe('phone/' + something.uuid, function (err) {
        if (!err) { }
      });

      something.mqttClient.publish('phones', something.uuid + ";" + something.user);

      clearInterval(something.updatePhoneInterval);
      something.updatePhoneInterval = setInterval(() => {
        something.mqttClient.publish('phones', something.uuid + ";" + something.user);
      }, 5000);

    });

    this.mqttClient.on('message', function (topic, message) {
      message = message.toString();
      if(topic == "user/" + something.user) {
        let msg = message.split(";");
        // console.log(msg);
        if(msg[0] == something.waitDevice){ 
          something.deviceServ.stopWait(); 
          something.waitDevice = ""; 
          something.addModal.closeModal(); 
          something.addModal.switchDone(true); 
          something.addModal.switchStep("7");
          clearInterval(something.addModal.wifiConnectingInterval);
          something.deviceServ.AddDevice(msg[0], something.addModal.returnName(), something.addModal.returnMac(), something.addModal.returnWifiSSID(), something.addModal.returnWifiPassword());
          if(msg[0].indexOf("UNI") > -1) {
            something.sendMessage("device/" + msg[0], msg[0] + ";ac_setup;-2");
          }
        } else if(msg[0] == something.waitCommand){
          something.addModal.switchStep("9");
        }
        if(msg[1] == 'update'){
          let room_temperature = 0;
          if(msg[0].indexOf("UNI") > -1) { 
            if(msg[12] == undefined) { room_temperature = msg[10]; }
            else { room_temperature = msg[12]; } 
            if(msg[13] != "none" && msg[13] != '') { msg[11] += ";" + msg[13] }
            something.deviceServ.setIRPRotocol(msg[0], msg[11]);
          }

          if(something.scanningIRDevice == msg[0]) {
            something.scanningIRModal.switchView('detected_ir');
            something.clearIrDevice();
          }
          
          something.deviceServ.setDeviceInformation(msg[0], msg[2], msg[3], msg[4], msg[5], msg[8], room_temperature, msg[9], msg[6], msg[7]);
        }
        something.zone.run(() => { });
      } else if(topic == "phone-user/" + something.user) {
        let msg = message.split(";");
        if(msg[0] != something.uuid) {
          if(msg[1] == 'refresh_devices') { something.deviceServ.getDevices(); }
          else if(msg[1] == 'refresh_rooms') { something.deviceServ.getRooms(); }
          else if(msg[1] == 'refresh_schedules') { something.deviceServ.getSchedules(); }
        }
      } else if(topic == "phone/" + something.uuid) {
        if(message == "logout") {
          something.deviceServ.getPhoneInformation();
          something.mqttClient.end(true);
        }
      } else if(topic == "devices"){
        let msg = message.split(";");
        if(something.deviceServ.returnDevices().filter(dev => dev.serial == msg[0]).length > 0) {
          something.deviceServ.updateDevice(msg[0], msg[2]);
          if(something.connectingDevice == msg[0]) { something.sendMessage("device/" + msg[0], msg[0] + ";get_wifi"); }
        } else {
          if(msg[1] == something.user) { something.deviceServ.lateAdd(msg[0]); }
        }
      } else if(topic == "device/" + something.askingDevice){
        let msg = message.split(";");
        if(msg[1] == "retrieve_wifi") {
          let networks = [];
          for(let i = 2; i < msg.length; i++) {
            let network_split = msg[i].split("*");
            if(!network_split[0].includes("ICON-") && network_split[0] != "") { 
              networks.push({ name:network_split[0], signal:network_split[1], encryption:network_split[2] }); 
            }
          }
          something.deviceServ.LoadNetworksForDevice(msg[0], networks);
          something.deviceServ.stopWait();
          something.mqttClient.unsubscribe("device/" + something.askingDevice);
          something.askingModal.setScanning(true);
          something.askingDevice = "";
          something.askingModal = null;
        } else if(msg[1] == "magnetic_settings") {
          something.deviceServ.ChangeMagneticSettings(msg[0], msg[2], msg[3], msg[4], msg[5], msg[6], msg[7]);
          something.askingModal.current_view = "digital-input-settings";
          something.askingDevice = "";
          something.askingModal = null;
        }
      } else if(topic == "device/" + something.connectingDevice){
        let msg = message.split(";");
        if(msg[1] == "wifi_info") {
          if(something.connectingWifi == msg[2]) {
            something.mqttClient.unsubscribe("device/" + something.connectingDevice);
            something.connectingModal.switchView("connect-success");
            something.connectingDevice = "";
            something.connectingModal = null;
            something.connectingWifi = "";
            something.deviceServ.ChangeNetwork(msg[0], msg[2]);
          } else {
            something.mqttClient.unsubscribe("device/" + something.connectingDevice);
            something.connectingModal.switchView("connect-failed");
            something.connectingDevice = "";
            something.connectingModal = null;
            something.connectingWifi = "";
          }
        }
      } else {
        let msg = message.split(";");
        for(let i = 0; i < something.additonalUsers.length; i++) {
          if(topic == "user/" + something.additonalUsers[i]) {
            if(msg[1] == 'update'){
              let room_temperature = 0;
              if(msg[0].indexOf("UNI") > -1) {
                if(msg[12] == undefined) { room_temperature = msg[10]; }
                else { room_temperature = msg[12]; } 
              }
              something.deviceServ.setDeviceInformation(msg[0], msg[2], msg[3], msg[4], msg[5], msg[8], room_temperature, msg[9], msg[6], msg[7]);
            }
          }
        }
      }
    });

    this.mqttClient.on('error', function (err) {
      something.connectUs(user);
    })
  }

  AddAdditionalUser(user_id){ 
    let check_if_user_added = something.additonalUsers.filter(user => user == user_id);
    if(check_if_user_added.length == 0) {
      something.additonalUsers.push(user_id);
      if(something.mqttClient !== undefined) {
        something.mqttClient.subscribe('user/' + user_id, function (err) {
          if (!err) { }
        });
      }
    }

  }

  RemoveAdditionalUsers(user_id) {
    for(let i = 0; i < this.additonalUsers.length; i++){
      this.additonalUsers.slice(i, 1);
    }
  }

  ScanWifiForDevice(serial, askModal) {
    this.askingDevice = serial;
    this.sendMessage("device/" + serial, serial + ";scan_wifi");
    something.mqttClient.subscribe('device/' + serial, function (err) {});
    this.askingModal = askModal;
  }

  ConnectingWifiForDevice(serial, wifi_connect, connectingModal) {
    this.connectingDevice = serial;
    this.connectingModal = connectingModal;
    this.connectingWifi = wifi_connect;
    something.mqttClient.subscribe('device/' + serial, function (err) {});
  }

  GetMagneticSettings(serial, modal) {
      this.askingDevice = serial;
      this.askingModal = modal;
      this.sendMessage("device/" + serial, serial + ";get_magnetic_settings");
      something.mqttClient.subscribe('device/' + serial, function (err) {});
  }

  ScanIrDevice(serial, scanIrModal) {
    this.scanningIRDevice = serial;
    this.scanningIRModal = scanIrModal;
  }

  clearIrDevice() {
    this.scanningIRDevice = "";
    this.scanningIRModal = null;
  }

  disconnect() { if(something.mqttClient !== undefined) { something.mqttClient.end(true); } }

  setUUID(uuid){ this.uuid = uuid; }
  setUser(user) { this.user = user; }

  public sendMessage(topic, msg) { this.mqttClient.publish(topic, msg) }

  public waitForDevice(device, modal) { 
    this.waitDevice = device; 
    this.addModal = modal;
  }

  public waitForCommand(device, modal) {
    this.waitCommand = device;
    this.addModal = modal;
  }
}
