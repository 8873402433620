import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommunicationService } from '../communication.service';
import { DevicesService } from '../devices.service';

@Component({
  selector: 'app-view-room',
  templateUrl: './view-room.component.html',
  styleUrls: ['./view-room.component.scss']
})
export class ViewRoomComponent implements OnInit {
  uid:any;
  modal:any = { open:false, cntx:"", obj:{} };

  setTempTimeout:any;
  allowModal:any = true;

  dropdown:any = { open:false, cntx:'', obj:{} }

  constructor(private router:Router, private activatedRoute:ActivatedRoute, public deviceServ:DevicesService, private commServ:CommunicationService, public zone:NgZone) { }

  ngOnInit(): void {
    this.uid = this.activatedRoute.snapshot.params.uid;
  }


  switchPower(device) {
    if(device.active && device.communicated) {
      if(this.returnCurrentPower(device) == 'ON') {
        device.power_set = "OFF";
        setTimeout(()=>{ 
          this.commServ.sendMessage("device/" + device.serial, device.serial + ";power_off");
        },300);
      } else {
        device.power_set = "ON";
        setTimeout(()=>{ 
          this.commServ.sendMessage("device/" + device.serial, device.serial + ";power_on");
        },300);
      }
    }
  }

  switchMode(device, mode) {
    if(device.active && this.returnCurrentPower(device) == 'ON' && device.communicated) {
      if(mode == 0) {
        this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_mode;0");
        this.zone.run(() => { device.mode_set = "AUTO"; });
      }else if(mode == 1) {
        this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_mode;1");
        this.zone.run(() => { device.mode_set = "COOL"; });
      } else if(mode == 2) {
        this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_mode;2");
        this.zone.run(() => { device.mode_set = "DRY"; });
      } else if(mode == 3) {
        this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_mode;3");
        this.zone.run(() => { device.mode_set = "FAN"; });
      } else if(mode == 4) {
        this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_mode;4");
        this.zone.run(() => { device.mode_set = "HEAT"; });
      }
    }
  }

  switchFanSpeed(device, speed) {
    if(device.active && this.returnCurrentPower(device) == 'ON') {
      let final_speed = speed;
      if(final_speed == 1) { speed = 2; } else if(final_speed == 2) { speed = 5; }
      else if(final_speed == 3) { speed = 8; } else if(final_speed == 4) { speed = 11; }
      else if(final_speed == 5) { speed = 0; } 
      device.fan_speed_set = final_speed;
      this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_fan_speed;" + speed);
      this.zone.run(() => {  });
    }
  }



  
  setTemperature(device, temp) {
    if(this.returnIsDeviceActive(device) && this.returnCurrentPower(device) == 'ON' && temp > 15 && temp < 31  && this.returnIsDeviceCommunicating(device)) {
      if(this.returnCurrentMode(device) != 'FAN') {
        this.deviceServ.returnDevice(device.serial).temp_set = temp;
        clearTimeout(this.setTempTimeout);
        this.setTempTimeout = setTimeout(()=>{ 
          if(this.returnDeviceType(device) == "FGL") {
            this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_temperature;"  + (temp) * 10);
          } else { 
            this.commServ.sendMessage("device/" + device.serial, device.serial + ";set_temperature;"  + temp);
          }
          this.zone.run(() => {  });
        }, 500);

      }
    }
  }

  returnDevice(serial) {
    this.deviceServ.returnDevice(serial);
  }

  returnCurrentPower(device) {
    if(this.deviceServ.returnDevice(device.serial).power_set != "") { return this.deviceServ.returnDevice(device.serial).power_set; }
    else { return this.deviceServ.returnDevice(device.serial).power; }
  }

  returnCurrentTemp(device) {
    if(this.deviceServ.returnDevice(device.serial).temp_set != 0) { return this.deviceServ.returnDevice(device.serial).temp_set; }
    else { return this.deviceServ.returnDevice(device.serial).temperature; }
  }

  returnCurrentMode(device) {
    if(this.deviceServ.returnDevice(device.serial).mode_set != "") { return this.deviceServ.returnDevice(device.serial).mode_set; }
    else { return this.deviceServ.returnDevice(device.serial).mode; }
  }

  returnCurrentFanSpeed(device) {
    if(this.deviceServ.returnDevice(device.serial).fan_speed_set != "" ) { return parseInt(this.deviceServ.returnDevice(device.serial).fan_speed_set); }
    else { return this.deviceServ.returnDevice(device.serial).fan_speed; }
  }

  returnIsDeviceActive(device) { return this.deviceServ.returnDevice(device.serial).active; }
  returnIsDeviceCommunicating(device) { return this.deviceServ.returnDevice(device.serial).communicated; }

  returnDeviceType(device) {
    if(device.serial.indexOf("FGL") > -1){ return "FGL"; }
    else {  return "UNI"; }
  }

  IsDeviceUNI(device) {
    if(device.serial.indexOf("UNI") > -1) { return true; }
    else { return false; }
  }

  returnShadowCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

}
