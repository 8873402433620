<div class="web-header">
    <span class="title" style="width:350px;left:calc(50% - 175px)">Избрана стая - {{ deviceServ.returnRoom(uid).name }}</span>
    <div class="back-btn" routerLink="/rooms"></div>
    <!-- <div class="header-btn right add-ic"></div> -->
</div>
<div class="content-holder">
    <div class="ac-new-row" *ngFor="let device of deviceServ.returnRoomDevicesByUID(uid)">
        <div class='ac-new-row-header'>
          <div class="icon shared" (click)="presentModal(device)" *ngIf="device.shared" class="icon shared"></div>
          <div class="name" [ngClass]="{ 'not-shared':!device.shared }" (click)="presentModal(device)">
            {{ device.name }}
            <div class="house-temp" *ngIf="device.active && IsDeviceUNI(device) && device.communicated && device.updating == '0'">
              <i class="icon house"></i>{{device.room_temperature}}°C
              <div class="tooltip">
                <span class="tooltiptext">В момента</span>
              </div> 
            </div>
          </div>
          <div class="more" (click)="openDropdown('more-options', device)"></div>
          <div class='dropdown-list' clickOutside (onClickOutside)="closeDropdown()" [ngClass]="{ 'active':dropdown.open && dropdown.cntx == 'more-options' && dropdown.obj.serial == device.serial }">
            <span class="dropdown-title">{{ device.name }}</span>
            <div class="dropdown-item" (click)="openModal('device-info', device)"><i class="icon info"></i> <span>Информация за устройства</span></div>
            <div class="dropdown-item" (click)="openModal('rename-device', returnShadowCopy(device))"><i class="icon edit"></i> <span>Преименуване</span></div>
            <!-- <div class="dropdown-item"><i class="icon share"></i> <span>Споделяне</span></div> -->
            <div class="dropdown-item negative" (click)="openModal('remove-device', device)"><i class="icon remove"></i> <span>Изтриване</span></div>
          </div>
        </div>
        <div class="status-row">
          <div class="temp" (click)="presentModal(device)" *ngIf="returnCurrentPower(device) == 'ON' && returnCurrentMode(device) != 'FAN' && device.active && device.communicated && device.updating == '0'">{{ returnCurrentTemp(device) }}°C</div>
          <div class="temp inactive" (click)="presentModal(device)" *ngIf="returnCurrentPower(device) == 'OFF' && device.active && device.communicated && device.updating == '0' || returnCurrentMode(device) == 'FAN' && device.active && device.communicated && device.updating == '0'">--°C</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="!device.active && device.initialized && device.updating == '0'">{{ langServ.returnTranslate('label_no_connection') }}</div>
          <div class="temp connection updating" (click)="presentModal(device)" *ngIf="device.updating == '1'">Актуализация на устройство...</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="device.active && !device.communicated && device.initialized && !IsDeviceUNI(device) && device.updating == '0'">{{ langServ.returnTranslate('label_cabel_problem') }}</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="device.active && !device.communicated && device.initialized && IsDeviceUNI(device) && device.updating == '0'">{{ langServ.returnTranslate('label_no_protocol') }}</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="!device.initialized && device.updating == '0'">{{ langServ.returnTranslate('label_connecting') }}</div>
          <div class="options" *ngIf="returnCurrentPower(device) == 'ON' && device.active && device.communicated && device.updating == '0'">
            <div class="selectable-row">
              <span class="label">Режим</span>
              <div class="selectable-option" (click)="switchMode(device, 1)"><i class="icon" [ngClass]="{ 'cold':returnCurrentMode(device) == 'COOL', 'cool_selectable':returnCurrentMode(device) != 'COOL' }"></i></div>
              <div class="selectable-option" (click)="switchMode(device, 4)"><i class="icon" [ngClass]="{ 'hot':returnCurrentMode(device) == 'HEAT', 'heat_selectable':returnCurrentMode(device) != 'HEAT' }"></i></div>
              <div class="selectable-option" (click)="switchMode(device, 3)"><i class="icon" [ngClass]="{ 'fan':returnCurrentMode(device) == 'FAN', 'fan_selectable':returnCurrentMode(device) != 'FAN' }"></i></div>
              <div class="selectable-option" (click)="switchMode(device, 2)"><i class="icon" [ngClass]="{ 'dry':returnCurrentMode(device) == 'DRY', 'dry_selectable':returnCurrentMode(device) != 'DRY' }"></i></div>
              <div class="selectable-option" (click)="switchMode(device, 0)"><i class="icon" [ngClass]="{ 'auto':returnCurrentMode(device) == 'AUTO', 'auto_selectable':returnCurrentMode(device) != 'AUTO' }"></i></div>
            </div>
            <div class="selectable-row">
              <span class="label">ВЕНТИЛАТОР</span>
              <div class="selectable-option" (click)="switchFanSpeed(device, 1)" [ngClass]="{ 'active': returnCurrentFanSpeed(device) == 1 }"><i class="icon fan-quiet"></i></div>
              <div class="selectable-option" (click)="switchFanSpeed(device, 5)" [ngClass]="{ 'active': returnCurrentFanSpeed(device) == 5 }"><i class="icon fan-auto"></i></div>
              <div class="selectable-option" (click)="switchFanSpeed(device, 2)" [ngClass]="{ 'active': returnCurrentFanSpeed(device) == 2 }"><i class="icon fan-low"></i></div>
              <div class="selectable-option" (click)="switchFanSpeed(device, 3)" [ngClass]="{ 'active': returnCurrentFanSpeed(device) == 3 }"><i class="icon fan-medium"></i></div>
              <div class="selectable-option" (click)="switchFanSpeed(device, 4)" [ngClass]="{ 'active': returnCurrentFanSpeed(device) == 4 }"><i class="icon fan-high"></i></div>
            </div>
          </div>
        </div>
        <div class="controls-row">
          <div class="control-btn" (click)="setTemperature(device, returnCurrentTemp(device) + 1)"><div class="inner"><i [ngClass]="{ 'disabled':returnCurrentPower(device) == 'OFF' || returnCurrentMode(device) == 'FAN' || !device.active || !device.communicated  }" class="icon temp-up"></i></div></div>
          <div class="control-btn"(click)="setTemperature(device, returnCurrentTemp(device) - 1)"><div class="inner"><i [ngClass]="{ 'disabled':returnCurrentPower(device) == 'OFF' || returnCurrentMode(device) == 'FAN' || !device.active || !device.communicated }" class="icon temp-down"></i></div></div>
          <div class="device-power-btn"  [ngClass]="{ 'on':returnCurrentPower(device) == 'ON', 'off':returnCurrentPower(device) == 'OFF', 'inactive':!device.active || !device.communicated || device.updating == '1' }" (click)="switchPower(device)"><div class="inner"><i class="icon power"></i></div></div>
        </div>
        <div class="gray-bg"></div>
    </div>
</div>